import NextImage from 'next/image';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

import CarBack from '@means/assets/png/car-back.png';
import { BaseButton } from '@screen/components/ui/BaseButton';

import styles from './styles.module.css';

export const Main = (): JSX.Element => {
  const { push } = useRouter();

  const handleBackToHome = useCallback(() => {
    push('/');
  }, [push]);

  return (
    <section>
      <div className={styles.main}>
        <div className="py-20 flex items-center flex-col justify-center text-center">
          <div>
            <h1 className={styles.title}>404</h1>

            <h3 className="font-bold text-4xl">Página não encontrada</h3>

            <BaseButton
              onClick={handleBackToHome}
              className="w-max px-8 mt-6 mx-auto flex items-center font-bold duration-200 rounded-full text-typography-white bg-gradient-to-r to-floor-green from-floor-green-300 z-10 relative"
            >
              <span className="text-xl">VOLTAR Á PÁGINA ANTERIOR</span>
            </BaseButton>
          </div>

          <div className={styles.car}>
            <NextImage src={CarBack} />
          </div>
        </div>
      </div>
    </section>
  );
};
